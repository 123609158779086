// Here you can add other styles
.full-width {
   width: 100%;
}

.profile-img {
   border-radius: 100px;
   height: 100px;
}

.pull-right {
   float: right;
}


.profile-table th {
   padding: 5px
}

.profile-table td {
   padding: 5px
}

.register-bg {
   background-image: url('../assets/images/register.jpg');
   background-size: contain;
   background-repeat: no-repeat;
}

.nav-link {
   font-family: PoppinsSemiBold;
}

.bg-light {
   background-color: $light-gray !important;
}

.vehicle-types-cards {
   background-color: #fff;
   height: 125px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 15px !important;
   align-items: center !important;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   text-align: center;
   border-radius: 10px;
   text-decoration: none;
}

.vehicle-types-cards span {
   font-family: PoppinsSemiBold;
   color: $black
}

.quotation-list-header {
   background-color: #fff;
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 15px !important;
   align-items: center !important;
   //  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   text-align: center;
   border: 0.5px solid #d3d3d3;
   text-decoration: none;
}

h4 {
   font-family: PoppinsSemiBold;
}

.subtitle {
   font-family: PoppinsLight;
}

.w-50 {
   width: 50%;
}

.b-right {
   border-right: 1px solid #d3d3d3;
}

.card-header {
   background-color: #fff;
   display: inline-block;
   padding: 15px;
   ;
}


.card-heading {
   display: inline-block;
}

.btn-light-blue {
   background-color: #0A6FE8;
   color: #FFF;
   font-family: PoppinsSemiBold;
}

.btn-light-blue:hover {
   background-color: #0A6FE8;
   opacity: 0.9;
   color: #FFF;
   font-family: PoppinsSemiBold;
}


.saved-quotation-list-card {
   border-bottom: 1px solid #d3d3d3;
   display: flex;
   flex-direction: row;
   padding: 15px;
   text-decoration: none;

}

.saved-quotation-list-card .logo-cotainer {
   width: 10%;

}

.saved-quotation-list-card .details-container {
   width: 70%;
   text-align: left;
   display: flex;
   flex-direction: column;
}

.saved-quotation-list-card .time-container {
   width: 23%;
   text-align: right;
   display: flex;
   flex-direction: column;
   color: #000;
}


.saved-quotation-list-card .time-container .bell-icon {
   height: 21px;
   width: 21px;
   align-self: end;
   color: #000;

}

.qtl-vehicle-make {
   font-family: PoppinsSemiBold;
   font-size: 21px;
   color: #000;

}

.qtl-vehicle-model {
   font-family: PoppinsLight;
   color: #0A6FE8;
}

.qtl-vehicle-rto {
   font-family: PoppinsLight;
   color: #000;

}

.btn-fill {
   background-color: #1267c1;
   border: 1px solid #1267c1;
   color: #FFF;
   font-family: PoppinsSemiBold;

}

.btn-fill:hover {
   background-color: #0A6FE8;
   border: 1px solid #0A6FE8;
   color: #FFF;
}

.mr-10 {
   margin-right: 10px;
}

.btn-outline {
   background-color: #FFF;
   border: 1px solid #1267c1;
   color: #1267c1;
   font-family: PoppinsLight;

}


.btn-outline:hover {
   background-color: #0A6FE8;
   border: 1px solid #0A6FE8;
   color: #fff;
   font-family: PoppinsLight;

}

.label {
   color: #0876BE;
   font-size: 13px;
   font-family: PoppinsSemiBold;
}

.normal-text {
   color: #000;
   font-size: 13px;
   font-family: PoppinsRegular;
}

.select-box {
   font-family: PoppinsRegular;
}

.link-btn {
   text-decoration: none !important;
   padding: 10px !important;
   border-radius: 25px !important;
}



.suggestion-quotation-list-card {
   border: 0.5px solid #d3d3d3;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   border-radius: 5;

}

.suggestion-quotation-list-card .insurer-details {
   display: flex;
   flex-direction: row;
   padding: 15px;


}

.suggestion-quotation-list-card .payout-details {
   display: flex;
   flex-direction: row;
   border-top: 0.5px solid #d3d3d3;
   padding: 15px;


}

.suggestion-quotation-list-card .payout-details .payout {
   width: 33.33%
}

.suggestion-quotation-list-card .payout-details .discount {
   width: 33.33%
}

.suggestion-quotation-list-card .payout-details .no-add-on-plan {
   width: 33.33%
}

.suggestion-quotation-list-card .payout-details .payout span {

   margin-right: 10px;
   font-family: PoppinsRegular;
}

.suggestion-quotation-list-card .payout-details .discount span {

   margin-right: 10px;
   font-family: PoppinsRegular;
}


.suggestion-quotation-list-card .payout-details .no-add-on-plan span {
   float: right;
   font-family: PoppinsRegular;
}


.suggestion-quotation-list-card .logo-cotainer {
   width: 10%;

}

.suggestion-quotation-list-card .details-container {
   width: 70%;
   text-align: left;
   display: flex;
   flex-direction: column;
}

.suggestion-quotation-list-card .time-container {
   width: 23%;
   text-align: right;
   display: flex;
   flex-direction: column;

}


.suggestion-quotation-list-card .time-container .next-btn {
   height: 50px;
   width: 50px;
   align-self: end;
}


.proposal-summary th td {
   // border: 0.5px solid #d3d3d3;
   padding: 10px;
   border-collapse: collapse;
}


.view-more-btn {
   font-family: PoppinsSemiBold;
   color: #fff
}

.view-more-btn:hover {
   font-family: PoppinsSemiBold;
   color: #fff
}

.view-more-dropdown-menu {
   font-family: PoppinsSemiBold;
}

.cover-dropdown-item:hover {
   background-color: #d3d3d3;
   color: #000;
   border-radius: 5px;
}

.add-on-cover-plans {
   background-color: lightgreen;
   color: #000;
   border-radius: 5px;
   margin-right: 10px;
   padding: 5px 10px;
   margin-top: 5px;
   width: auto;
   display: inline-block;
   font-size: 11px;
   font-family: PoppinsSemiBold;


}


.select__menu {
   z-index: 20 !important;
}

.back-btn {
   border-radius: 5px !important;
   // height: 15;
   padding: 5px 10px !important;
}

.card-sub-heading {
   display: inline-block;
   font-size: 18px;
   font-family: PoppinsRegular;

}



.profile-label {
   color: #0876BE;
   font-size: 15px;
   font-family: PoppinsSemiBold;
}

.profile-label-value {
   color: #000;
   font-size: 15px;
   font-family: PoppinsRegular;
}


@media only screen and (max-width: 480px) {
   .footer_txt {
      margin-left: auto;
      margin-right: auto;
   }
}

.btn-primary{
   // background-color: #1267c1 !important;
}

.sidebar-brand, .sidebar-toggler {
   background-color: #fff;
}


.sidebar {
   background-color: #fff;
   border-right: 1px solid #d8dbe0 !important;
}

.sidebar-nav .nav-icon{
   color:#212b36   !important; 
}

.sidebar-nav .nav-link.active .nav-icon{
   color:#fff   !important; 
}

.sidebar-nav .nav-link.active{
   color:#fff   !important; 
   font-family: PoppinsRegular;
   background-color: #1267c1;
   border: 1px solid #1267c1;
   // margin: 0px 10px;

   border-radius: 10px;


}

.sidebar-nav .nav-item{
   padding:5px 10px 5px 10px;
}

.sidebar-nav .nav-link:hover{
   color:#fff   !important; 
   font-family: PoppinsRegular;
   background-color: #1267c1;
   border: 1px solid #1267c1;
   // margin: 0px 10px;
   border-radius: 10px;
   

}


.sidebar-nav .nav-link:hover  .nav-icon{
   color:#fff   !important; 
}



.sidebar-nav .nav-link{
   color:#212b36   !important; 
   font-family: PoppinsRegular;

}

.sidebar-nav .nav-title{
   color:#212b36   !important; 
   font-family: PoppinsSemiBold;


}


.sidebar-brand {
   background-color: #fff;
}

.sidebar-toggler{
   background-color: #fff;

}

.sidebar-toggler{
   display: none !important;
}


@media (max-width: 768px){
.app-header-mobile-logo {
    display: none!important;
}

.card-heading {
   font-size: 17px;
   margin-top:10px
}
}

.app-header {
   margin-left: 10px !important;
}

.login-btn{
   background-color: #1267c1  !important;
   border: 1px solid #1267c1 ;
}

.login-btn:hover{
   background-color: #1267c1  !important;
}

.table-menu-icon{
   font-size: 1.2rem;
   vertical-align: middle !important;
}

.table-menu-label{
   vertical-align: middle !important;
   font-family:PoppinsRegular;
   font-size: 1.1rem;
}

.row-padding-bottom-20{
   padding-bottom: 20px;;
}